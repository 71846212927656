@import 'src/assets/styles/mixin';
@import 'src/assets/styles/variables';


.privacy {
  @include textStyle(0.75rem);
  color: white;

  ul {
    padding-left: 30px;
  }

  a {
    color: #0CF;
  }
}
