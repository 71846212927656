@import 'src/assets/styles/mixin.scss';
@import 'src/assets/styles/variables.scss';


@import 'src/assets/styles/mixin';
@import 'src/assets/styles/variables';


.menu {
  padding: 5px;
  width: 330px;
  max-width: 100%;
  flex-shrink: 0;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: rgba(8, 8, 8, 1);
  box-shadow: -5px 5px 15px 0px rgba(0, 0, 0, 0.40);
  max-height: calc(100vh - 80px);
  max-height: calc(100dvh - 80px);

  .menuList {
    padding-top: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 7px;
    overscroll-behavior: contain;

    &::-webkit-scrollbar-thumb {
      background-color: #00CCFF !important;
    }
  }
}

.subMenuItem {
  margin-top: 8px;
  padding-left: 15px;
  padding-right: 0;
  border-left: 5px solid transparent;
  min-height: 20px;
  width: 100%;
  @include flex(center);
  @include textStyle(0.75rem);
  color: #ABABAB;
  cursor: pointer;
  transition: all 0.15s;

  &:hover {
    border-color: #0A3A5C;
    color: white;
  }

  &.subMenuItemActive {
    border-color: #0CF;
    color: white;
    font-weight: 600;
  }
}



.logo {
  max-width: 85px;
  overflow: hidden;

  > img {
    margin-left: -35px;
    width: 120px;
    height: auto;
    max-height: 36px;
    cursor: pointer;
    transition: transform .3s;

    &:hover {
      transform: scale(0.9)
    }

    &:active {
      transform: scale(1)
    }
  }
}

@media #{$xsDOWN} {
  .logo {
    max-width: 70px;

    > img {
      margin-left: -30px;
      width: 100px;
    }
  }
}
