@import 'src/assets/styles/mixin.scss';
@import 'src/assets/styles/variables.scss';

.toggleSiteMenu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  height: 100vh;
  height: 100dvh;
  width: 50px;
  @include flex(center, flex-end);
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto;

  &.toggleSiteMenuVR {
    background-image: url(../../../src/assets/images/maskVR1.png);
  }

  &.toggleSiteMenuSomnium {
    background-image: url(../../../src/assets/images/maskV3.png);
  }

  .buttonsBlock {
    //Need to fix mask bg position
    padding-top: 9px;
    @include flexCenter;
    flex-direction: column;

    button {
      cursor: pointer;
      @include button();
      @include flexCenter;
      width: 38px;
      height: 250px;
      border-radius: 20px 0 0 20px;
      background: #282828;
      //background: red;
      border: 1px solid #0CF;
      box-shadow: 0 0 10px 0 rgba(0, 204, 255, 0.50);

      -webkit-animation: shine 3s infinite;
      animation: shine 3s infinite;

      transition: all 0.2s linear;

      &:hover {
        border: 2px solid #1E78B4;
        border-right-color: transparent;
        box-shadow: none;
        animation: none;
        -webkit-animation: none;
      }

      &:active {
        background: #19507D;
        border: 2px solid transparent;
      }

      &:first-child {
        margin-bottom: 30px;
      }

      &.active {
        cursor: default;
        pointer-events: none;
        background: transparent;
        //background: red;
        border: 1px solid transparent;
        box-shadow: none;
        animation: none;
        -webkit-animation: none;
      }

    }
  }
}

@media (max-width: 1500px) {
  .toggleSiteMenu {
    width: 40px;
  }
}

@-webkit-keyframes shine {
  0% {
    border: 1px solid #0CF;
    box-shadow: 0 0 10px 0 rgba(0, 204, 255, 0.50);
  }
  50% {
    border: 1px solid transparent;
    box-shadow: 0 0 10px 0 transparent;
  }
  100% {
    border: 1px solid #0CF;
    box-shadow: 0 0 10px 0 rgba(0, 204, 255, 0.50);
  }
}

@keyframes shine {
  0% {
    border: 1px solid #0CF;
    box-shadow: 0 0 10px 0 rgba(0, 204, 255, 0.50);
  }
  50% {
    border: 1px solid transparent;
    box-shadow: 0 0 10px 0 transparent;
  }
  100% {
    border: 1px solid #0CF;
    box-shadow: 0 0 10px 0 rgba(0, 204, 255, 0.50);
  }
}


