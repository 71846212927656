@import 'src/assets/styles/mixin';
@import 'src/assets/styles/variables';

.closeButton {
  position: sticky;
  top: 10px;
  right: 10px;
  @include flex(center, flex-end);
  @include button(5px);
  margin: 10px 10px 0 auto;
  padding: 5px;
  width: fit-content;

  @include textStyle(0.75rem, 400);
  color: #808080;
  text-transform: capitalize;

  .close {
    transition: unset;
    margin-right: 5px;
  }

  .cross {
    path {
      fill: #808080;
    }
  }

  &:hover {
    color: #ffffff;

    .cross {
      path {
        fill: white;
      }
    }
  }

  &:active {
    color: #00CCFF;

    .cross {
      path {
        fill: #00CCFF;
      }
    }
  }
}

.content {
  @include flex(center);
  flex-direction: column;
  padding: 15px 30px 30px;
  color: white;
  width: 100%;

  .title {
    @include flexCenter;
    text-align: center;
    @include textStyle(1.375rem, 700);
    margin-bottom: 20px;
  }

  .subTitle {
    @include flexCenter;
    text-align: center;
    @include textStyle(0.875rem, 400);
    margin-bottom: 30px;
  }
}

@media #{$DOWN500} {
  .content {
    padding: 15px 20px 30px
  }
}
