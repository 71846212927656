@import 'src/assets/styles/mixin.scss';
@import 'src/assets/styles/variables.scss';

.wrapper {
  position: absolute;
  //display: none;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  max-height: 100dvh;
  @include flex(center, flex-start);
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;

  transform: translate(100%);
  transition: transform 0.3s linear;

  //&.visible {
  //  display: block;
  //}

  &.siteTransform {
    z-index: 1;
    position: absolute;
    transform: translate(0);
  }

  &.siteStatic {
    margin-top: -60px;
    z-index: 0;
    position: static;
  }

  .main {
    position: relative;
    @include flex(center, flex-start);
    flex-direction: column;
    width: 100%;
  }
}
