@import 'src/assets/styles/mixin.scss';
@import 'src/assets/styles/variables.scss';


.navBox {
  display: flex;
  flex-wrap: nowrap;

  > button {
    margin-right: 15px;
  }

  > button:last-child {
    margin-right: 0;
  }
}

@media (max-width: 899px) {
  .navBox {
    display: none;
  }
}
