@import 'src/assets/styles/mixin.scss';
@import 'src/assets/styles/variables.scss';

.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  background: rgba(22, 20, 50, 0.49);
  backdrop-filter: blur(8.15485px);
  @include flex(center, center);
  flex-direction: column;
}

.loaderBox {
  position: relative;
  @include flex(center, center);

  svg {
    z-index: 1;
    width: 100px;
    height: 100px;
    min-width: 100px;
  }
}

.loader {
  position: absolute;
  width: 120px;
  height: 120px;
}

.loader:before,
.loader:after {
  content: '';
  border-radius: 50%;
  position: absolute;
  inset: 0;
  //box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3) inset;
}

.loader:after {
  box-shadow: 2px 0  2px rgb(22 196 27 / 30%) inset, 0 2px 0 #FF3D00 inset;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0)
  }
  100% {
    transform: rotate(360deg)
  }
}

//background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%);
//filter: drop-shadow(0px 0px 16.746410369873047px rgba(0, 0, 0, 0.50));
//backdrop-filter: blur(8.4px);


