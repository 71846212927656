@import 'src/assets/styles/mixin.scss';
@import 'src/assets/styles/variables.scss';

.footer {
  @include flexCenter;
  padding: 0 50px;
  //background: rgba(0, 0, 0, 0.70);
  background: rgba(0, 0, 0, 1);
  width: 100%;
  z-index: 999;
  color: white;
  min-height: 70px;

  .box {
    @include flex(center, space-between);
    flex-grow: 1;

    .logoBox {
      @include flex(center);
      flex-wrap: nowrap;

      .logo {
        display: flex;
        width: 120px;
        height: auto;
        max-height: 36px;
        cursor: pointer;
        transition: transform .3s;

        &:hover {
          transform: scale(1.1)
        }

        &:active {
          transform: scale(1)
        }
      }

      .text {
        margin-left: 10px;
        max-width: 290px;
        @include textStyle(0.625rem);
        line-height: 1.5;
      }
    }

    .linksBox {
      @include flexCenter;
      flex-wrap: nowrap;
      opacity: 0.6;

      .linksBoxInner {
        margin-right: 30px;
        @include flex();
        flex-direction: column;

        a {
          text-decoration: none;
          color: #ABABAB;
          @include textStyle(0.75rem);
          transition: all .2s;
          cursor: pointer;

          &:hover {
            color: white;
          }
        }

        //a:first-child {
        //  margin-bottom: 5px;
        //}

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .socialBox {
      @include flexCenter;
      flex-wrap: nowrap;

      > a {
        margin-right: 20px;
        //@include flexCenter;

        &:last-child {
          margin-right: 0;
        }

        .socialIcon {
          display: flex;
          //height: 16px;
          max-height: 16px;
          width: 16px;
          cursor: pointer;
          transition: transform .1s;

          &:hover {
            transform: scale(1.3)
          }

          &:active {
            transform: scale(1)
          }
        }
      }
    }

  }
}

@media #{$xlDOWN} {
  .footer {
    min-height: fit-content;
    padding: 20px 50px;

    .box {
      @include flex();
      flex-direction: column;

      .socialBox {
        order: 1;
        margin-bottom: 20px;
      }

      .linksBox {
        order: 2;
        margin-bottom: 20px;
      }

      .logoBox {
        order: 3;
      }
    }
  }
}

@media #{$smDOWN} {
  .footer {
    padding: 20px;

    .box {
      .socialBox {
        display: flex;
        justify-content: space-between;
        width: 100%;

        > a {
          margin-right: 0;
        }
      }

      .linksBox {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .linksBoxInner {
          margin-right: 0;
        }
      }
    }
  }
}

@media #{$xsDOWN} {
  .footer {
    .box {
      .linksBox {
        .linksBoxInner {
          a {
            font-size: 0.7rem;
          }
        }
      }

      .logoBox {
        a {
          max-width: 30px;
          overflow: hidden;
        }
      }
    }
  }
}
