@import 'src/assets/styles/mixin.scss';
@import 'src/assets/styles/variables.scss';

.container {
  //width: 100%;
  //max-width: 400px;
}

.toast {
  background: #1C1C28 !important;
  border-radius: 10px !important;
  padding: 10px 10px 12px !important;
}

.body {
  margin: 24px 0 auto !important;
  padding: 0 10px !important;
  @include textStyle(0.75rem, 500);
  font-style: normal;
  line-height: 1.4;
  color: white;

  div {
    @include textStyle(0.75rem, 500);
  }
}


