@import 'src/assets/styles/mixin';
@import 'src/assets/styles/variables';


.authencityWidget {
  width: 100%;
  @include flex(center, center);
  flex-direction: column;
  gap: 1rem;
  margin: 70px 0 50px;
  color: white;
}
