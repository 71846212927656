@import 'src/assets/styles/mixin.scss';
@import 'src/assets/styles/variables.scss';

.mobileMenu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  height: 100vh;
  height: 100dvh;
  width: 35px;
  @include flex(center, flex-end);
  overflow: hidden;

  .buttonsBlock {
    @include flexCenter;
    flex-direction: column;

    button {
      cursor: pointer;
      @include button();
      @include flexCenter;
      width: 32px;
      height: 70px;
      border-radius: 15px;
      border: 1px solid #0CF;
      box-shadow: 0 0 10px 0 rgba(0, 204, 255, 0.50);

      -webkit-animation: shine 3s infinite;
      animation: shine 3s infinite;
      transition: all 0.2s linear;


      &:first-child {
        margin-bottom: 20px;
      }

      &.active {
        cursor: default;
        pointer-events: none;
        background: transparent;
        //background: red;
        border: 1px solid transparent;
        box-shadow: none;
        animation: none;
        -webkit-animation: none;
      }

    }
  }
}

@-webkit-keyframes shine {
  0% {
    border: 1px solid #0CF;
    box-shadow: 0 0 10px 0 rgba(0, 204, 255, 0.50);
  }
  50% {
    border: 1px solid transparent;
    box-shadow: 0 0 10px 0 transparent;
  }
  100% {
    border: 1px solid #0CF;
    box-shadow: 0 0 10px 0 rgba(0, 204, 255, 0.50);
  }
}

@keyframes shine {
  0% {
    border: 1px solid #0CF;
    box-shadow: 0 0 10px 0 rgba(0, 204, 255, 0.50);
  }
  50% {
    border: 1px solid transparent;
    box-shadow: 0 0 10px 0 transparent;
  }
  100% {
    border: 1px solid #0CF;
    box-shadow: 0 0 10px 0 rgba(0, 204, 255, 0.50);
  }
}


