@import 'src/assets/styles/mixin.scss';
@import 'src/assets/styles/variables.scss';

.header {
  @include flexCenter;
  position: sticky;
  top: 0;
  padding: 0 50px;
  background: rgba(0, 0, 0, 0.70);
  width: 100%;
  height: 60px;
  min-height: 60px;
  z-index: 999;

  .box {
    @include flex(center, space-between);
    flex-grow: 1;

    .logo {
      > img {
        display: flex;
        width: 120px;
        height: auto;
        max-height: 36px;
        cursor: pointer;
        transition: transform .3s;

        &:hover {
          transform: scale(1.1)
        }

        &:active {
          transform: scale(1)
        }
      }
    }

    .socialBox {
      @include flexCenter;
      flex-wrap: nowrap;

      .socialIcon {
        display: flex;
        //height: 16px;
        max-height: 16px;
        width: 16px;
        cursor: pointer;
        margin: 0 10px;
        transition: transform .1s;

        &:hover {
          transform: scale(1.3)
        }

        &:active {
          transform: scale(1)
        }
      }
    }

    .buttonBox {
      display: flex;
      flex-wrap: nowrap;
    }
  }
}


@media #{$DOWN1200} {
  .header {
    .box {
      .socialBox {
        display: none;
      }
    }
  }
}

@media #{$IpadDOWN} {
  .header {
    .box {
      .logo {
        overflow: hidden;
        max-width: 30px;

        > img {
          &:hover,
          &:active {
            transform: scale(1);
          }
        }
      }
    }
  }
}

@media (max-width: 899px) {
  .header {
    padding: 0 50px 0 35px;

    .box {
      .logo {
        display: none;
      }
    }
  }
}

@media #{$smDOWN} {
  .header {
    padding: 0 20px 0 5px;
  }
}
