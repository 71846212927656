@mixin textStyle($size: 1rem, $weight: 400) {
  font-family: 'Exo', sans-serif;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
}

@mixin textStyleMontserrat($size: 1rem, $weight: 400) {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
}

@mixin flex($align: flex-start, $justify: flex-start) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin scaleAnimation($name, $scaleIn: 1, $scaleOut: 0.9, $speed: 0.25s) {
  animation: #{$name} $speed forwards;

  @keyframes #{$name} {
    from {
      transform: scale($scaleIn);
    }
    to {
      transform: scale($scaleOut);
    }
  }
}

@mixin button($radius: 0px) {
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  border-radius: $radius;
}

@mixin lineClamp($lineClamp: 1) {
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  display: inline-block;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lineClamp;
  -webkit-box-orient: vertical;
  line-clamp: $lineClamp;
}
