@import 'src/assets/styles/mixin.scss';
@import 'src/assets/styles/variables.scss';

.button {
  @include flexCenter;
  height: 50px;
  width: 100%;
  @include button(25px);
  @include textStyle(1.25rem, 500);
  color: #FFF;
  transition: all 0.1s;

  &:hover {
    text-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
  }

  &.primary {
    background: #1474B8;

    &:hover {
      background: #47A7EB;
    }

    &:active {
      background: #0A3A5C;
    }
  }

  &.secondary {
    border: 2px solid #1474B8;

    &:hover {
      background: #0A3A5C;
    }

    &:active {
      background: #47A7EB;
    }
  }
}
