@import 'src/assets/styles/mixin.scss';
@import 'src/assets/styles/variables.scss';

%rightBorder {
  z-index: 1;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 10px 0 0 10px;
  width: 10px;
}

%titleStyle {
  z-index: 1;
  position: absolute;
  top: 11px;
  left: 20px;
  right: 0;
  @include textStyle(1rem, 700);
  font-style: normal;
  line-height: 1.4;
  color: white;
}

.Toastify__toast--success {
  &:after {
    @extend %titleStyle;
    content: 'Success Message';
    color: #17FF9A;
  }

  &:before {
    @extend %rightBorder;
    background: linear-gradient(0deg, #22D63A 0.57%, #001C09 100%);
  }
}

.Toastify__toast--warning {
  &:after {
    @extend %titleStyle;
    content: 'Warning Message';
    color: #f1c40f;
  }

  &:before {
    @extend %rightBorder;
    background: linear-gradient(0deg, #f1c40f 0.57%, #3D3204FF 100%);
  }
}


.Toastify__toast--error {
  &:after {
    @extend %titleStyle;
    content: 'Error Message';
    color: #F00;
  }

  &:before {
    @extend %rightBorder;
    background: linear-gradient(0deg, #FF0F00 0.56%, #3D0000 100%);
  }
}

.Toastify__toast--info {
  &:after {
    @extend %titleStyle;
    content: 'Advice Message';
    color: #0CF;
  }

  &:before {
    @extend %rightBorder;
    background: linear-gradient(0deg, #0CF 0.56%, #001644 100%);
  }
}


.Toastify__toast-icon {
  display: none !important;
}


